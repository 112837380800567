<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Reservas</li>
    </ol>
  </nav>
  <div class="table-responsive">
		<div class="form-group">
			<div class="row">
				<div class="form-group col-md-10">
					<div class="form-check">
						<input class="form-check-input" id="inactive" type="checkbox" v-model="showAll">
						<label class="form-check-label" for="inactive">Mostrar todos os pedidos pendentes (ignora os outros filtros)</label>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="form-group col-md-3">
					<input type="date" class="form-control" v-model="availableDate" >
				</div>
				<div class="col-md-7 col-xs-7">
					<!-- <input v-model="param" placeholder="Digite para pesquisar" class="form-control" @keypress="callSearch"> -->
					<InputSelect collection="locations" object="location" fieldKey="id" fieldText="name" @select="(sel) => location_id = sel" rows="200"/>
				</div>
				<div class="col-md-2 col-xs-2">
					<a type="button" @click="search()" class="btn btn-dark">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
							<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
						</svg>
					</a>
				</div>
			</div>
		</div>

    <div class="card mb-1" v-for="record in records" :key="record.id">
      <div :class="`row card-body ${this.getStatusClass(record)}`">
        <div class="col-10">
          <p class="card-text"><strong>{{ record.location.name }}</strong> - {{ this.weekDays[record.weekday] }} / {{ record.allday ? 'Dia todo' : `${record.starttime} - ${record.endtime}` }}</p>
          <p class="card-text">{{ this.getStatusDescription(record) }}{{ record.LocationReservations?.[0]?.associate ? ` - ${record.LocationReservations[0].associate.name}` : '' }}</p>
        </div>
        <div class="col-2">
          <button v-if="this.getStatusClass(record) == 'prebooked'" type="button" class="btn btn-light" @click="changeStatusReservation('reserved', record.LocationReservations[0])" data-toggle="tooltip" title="Confirmar reserva">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-calendar2-check" viewBox="0 0 16 16">
              <path d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/> <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/> <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
            </svg>
          </button>
          <button v-if="this.getStatusClass(record) == 'prebooked' || this.getStatusClass(record) == 'reserved'" type="button" class="btn btn-light ml-1" @click="changeStatusReservation('canceled', record.LocationReservations[0])" data-toggle="tooltip" title="Excluir reserva">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-calendar2-x" viewBox="0 0 16 16">
              <path d="M6.146 8.146a.5.5 0 0 1 .708 0L8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 0 1 0-.708z"/> <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/><path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
            </svg>
          </button>
          <button v-if="this.getStatusClass(record) == 'available'" type="button" class="btn btn-light" @click="showModalReservationEdit(record.id)" data-toggle="tooltip" title="Adicionar reserva">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <New v-if="showModal" @close="showModal = false" v-on:refresh="(r) => fetchGetData(r)" :id="reservationSelected" v-bind:location_availability_id="reservationSelected" v-bind:startdate="availableDate"/>
  
  </div>
</template>

<script>
import { sAlert, sDialog } from '../utils/jalerts';
import ApiAxios from '../../services/api-axios';
import moment from 'moment';
import InputSelect from '../commons/InputSelect.vue';
import New from './New.vue';

export default {
  data() {
    return {
			showModal: false,
			reservationSelected: null,
			availableDate: moment().format('YYYY-MM-DD'),
			location_id: 0,
			records: [],
			showAll: false,
			strFields: '',
			page: 1,
			size: 10,
			pages: 0,
			sort: '',
			param: '',
			weekDays: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
      statusMap: { 'available': 'Disponível', 'prebooked': 'Pré-reservado', 'reserved': 'Reservado', 'unavailable': 'Indisponível', 'canceled': 'Cancelado' }
		};
  },
  components: {
    InputSelect,
    New
  },
  watch: {
    availableDate() {
      this.fetchGetData();
    }
  },
  computed: {
		filters() {
			let param = {};
			if (this.location_id) param.location_id = this.location_id;

			return param;
		},
	},
  methods: {
		callSearch(e) {
			if (e.keyCode === 13) this.search();
		},
		async fetchGetData() {
			this.$root.loadingVisible = true;
			let url = 'locations/availables';
			if (this.showAll == true) url = 'locations/prebookeds';
			ApiAxios.get(url, {
				startdate: this.availableDate,
				showAll: this.showAll,
				params: {
					page: 0,
					sort: this.sort,
					size: 100,
					filter: this.filters,
				}
			})
			.then((res) => {
				this.records = res.data.availabilities;
				this.pages = res.data.totalPages;
				this.$root.loadingVisible = false;
			})
			.catch((e) => {
				this.$root.loadingVisible = false;
				if (e.response.status == 403) {
					this.$router.back();
					return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
				}
				sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
		},
		search() {
			if(this.page === 1) { return this.fetchGetData(); }
			this.page = 1;
		},
		getStatusClass(record) {
			if (record.unavailable) return 'unavailable';
			if (record.LocationReservations?.[0]?.status == 'prebooked') return 'prebooked';
			if (record.LocationReservations?.[0]?.status == 'reserved') return 'reserved';
			return 'available';
		},
		getStatusDescription(record) {
			if (record.unavailable) return 'Indisponível';
			if (record.LocationReservations?.[0]?.status == 'prebooked') return 'Pré-reservado';
			if (record.LocationReservations?.[0]?.status == 'reserved') return 'Reservado';
			return 'Disponível';
		},
		showModalReservationEdit(id) {
      this.reservationSelected = id;
			this.showModal = true;
    },
    changeStatusReservation(status, reservation) {
      sDialog({
				text: `Deseja alterar o status para ${this.statusMap[status]}?`,
				confirmFunction() {
					ApiAxios.put(`locationReservations/${reservation.id}`, { locationReservation: { status } })
            .then(() => {
              sAlert({ type: 'success', text: 'Registro gravado com sucesso.' });
              this.fetchGetData();
            })
            .catch((e) => {
              if (e.response.status == 403) {
                return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
              }
              sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
            }); 
				},
			})
    }
	},
}
</script>

<style scoped>
.unavailable {
	background-color: rgba(255, 46, 46, 0.3); 
}
.prebooked {
	background-color: rgba(237, 245, 123, 0.3); 
}
.reserved {
	background-color: rgba(84, 100, 246, 0.3); 
}
.available {
	background-color: rgba(142, 250, 158, 0.3); 
}
</style>