<template>
  <transition name="modal">
    <div tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static"
      data-keyboard="false" class="modal-mask" >
      <div class="modal-wrapper">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Reserva</h4>
              <button type="button" @click="closeModal()" class="close"><span >&times;</span></button>
            </div>
            <div class="modal-body bg-light">
              <Form :save="parentMethod" v-bind:objLocationReservation="locationReservation" @close="closeModal()"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Form from './Form.vue';
import ApiAxios from '../../services/api-axios';
import { sAlert } from '../utils/jalerts';

export default {
  props: {
    location_availability_id: String,
    startdate: String,
  },
  components: {
    Form,
  },
  data() {
    return {
      locationReservation: {
        location_availability_id: this.location_availability_id,
        startdate: this.startdate
      }
    }
  },
  methods: {
    async parentMethod(locationReservation) {
      this.$root.loadingVisible = true;
      ApiAxios.post(`locationReservations`, { locationReservation })
        .then(() => {
          this.$root.loadingVisible = false;
          sAlert({ type: 'success', text: 'Registro gravado com sucesso.' });
          this.$emit('close');
          this.$emit('refresh');
        })
        .catch((e) => {
          this.$root.loadingVisible = false;
          if (e.response.status == 403) {
            return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
          }
          sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
        });
    },
    closeModal() {
      this.$emit('close');
    }
  },  
}
</script>  
