<template>
  <form @submit.prevent="validateAndSubmit" id="formUser" >
    <div class="form-row">
      <div class="form-group col-md-12">
        <label>Associado</label>
				<InputSearch inputName="activity-associate" collection="associates" object="associate" fieldKey="id" fieldText="name" current='' @select="(sel) => this.locationReservation.associate_id = sel" rows="200" :filter="{inactive: false}"/>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-8">
        <label for="role">Status</label>
        <select class="form-control" v-model="locationReservation.status">
          <option value="prebooked">Pré-reservado</option>
          <option value="reserved">Reservado</option>
        </select>
      </div>
    </div>
    <div style="float: right;">
      <button type="button" class="btn btn-primary" v-on:click="validateAndSubmit">Salvar</button>
      <button type="button" class="btn btn-default" style="margin-left:10px;" @click="$emit('close')">Fechar</button>
    </div>
  </form>
</template>

<script>
import InputSearch from '../commons/InputSearch.vue';
import { sAlert } from '../utils/jalerts';

export default {
  props: {
    save: Function,
    objLocationReservation: Object
  },
  components: {
    InputSearch
  },
  data() {
    return {
      locationReservation: {},
      validationErrors: [],
    };
  },
  mounted() {
    if(!this.objLocationReservation.location_availability_id) this.$emit('close');
    this.locationReservation = this.objLocationReservation;
  },
  methods: {
    validateAndSubmit() {
      this.validationErrors = [];
      if (!this.validate()) {
        return sAlert({
          type: 'warning',
          text: 'Ops! ' + this.validationErrors,
        });
      }
      
      this.save( this.locationReservation );
    },
    validate() {
      const errors = [];
      if (!this.locationReservation.associate_id) errors.push("Associado inválido");
      if (!this.locationReservation.status) errors.push("Status inválido");

      if (errors) this.validationErrors = errors;
      else this.validationErrors = [];

      return !this.validationErrors.length;
    },
  },
}
</script>
